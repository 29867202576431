import React from 'react'
import { Link } from 'gatsby'
import './pageList.scss'

function PageList({ items }) {
  const listItems = items.map((item, i) => {
    return (
      <li key={i}>
        <h2 className="heading-2">
          <Link to={item.url}>
            {item.heading}
          </Link>
        </h2>
        <p className="list-text"><span className="tag">{item.tag}</span>{item.listText}</p>
        <p>
          <span className="page-list__tag meta">
            
          </span>        
          <span className="page-list__published meta">
            {item.published}
          </span>
        </p>
      </li>
    )
  })

  return <ul className="page-list">{listItems}</ul>
}

export default PageList
