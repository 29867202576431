import React from 'react'
import { graphql } from 'gatsby'
import NewsListPage from './newslistPage'
import linkResolver from '../../contentful/linkResolver'
import moment from 'moment'
import 'moment/locale/sv'
import localizationFilter from '../../contentful/localizationFilter'

const NewslistPageContainer = ({ data, pageContext }) => {
  const { contentfulNewslistPage, contentfulNews } = data
  const { breadcrumbs } = pageContext

  moment.locale(contentfulNewslistPage.node_locale.slice(0, 2))

  const newslistPage = {
    _doc: contentfulNewslistPage,
    locale: contentfulNewslistPage.node_locale,
    heading: contentfulNewslistPage.heading,
    path: linkResolver(contentfulNewslistPage),
    metaTitle:
      contentfulNewslistPage.metaTitle || contentfulNewslistPage.heading,
    metaDescription: contentfulNewslistPage.metaDescription,
    introduction: contentfulNewslistPage.introduction
      ? contentfulNewslistPage.introduction.introduction
      : null,
    topImage: contentfulNewslistPage.topImage
      ? contentfulNewslistPage.topImage.fluid
      : null
  }

  const localizedNews = contentfulNews.edges.filter(
    article => article.node.slug
  )

  const newsItems = localizedNews.filter(localizationFilter).map(({ node }) => {
    return {
      heading: node.heading,
      listText: node.listText ? node.listText.listText : null,
      url: linkResolver(node),
      published: moment(node.published).format('MMM D'),
      tag: node.pageType === 'News article' ? 'Nyhet' : 'Pressmeddelande'
    }
  })

  return (
    <NewsListPage
      items={newsItems}
      doc={newslistPage}
      totalCount={pageContext.totalCount}
      pageNo={pageContext.currentPage}
      crumbs={breadcrumbs}
    />
  )
}

export default NewslistPageContainer

export const pageQuery = graphql`
  query($locale: String!, $slug: String!, $limit: Int!, $skip: Int!) {
    contentfulNewslistPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      node_locale
      slug
      heading
      topImage {
        fluid(maxWidth: 2000, maxHeight: 500, quality: 80) {
          ...GatsbyContentfulFluid
        }
      }
    }
    contentfulNews: allContentfulPage(
      filter: {
        pageType: { in: ["News article", "Press release"] }
        node_locale: { eq: $locale }
      }
      limit: $limit
      skip: $skip
      sort: { fields: [published], order: DESC }
    ) {
      edges {
        node {
          node_locale
          enableLocalization
          slug
          heading
          published
          listText {
            listText
          }
          pageType
        }
      }
    }
  }
`
