import React from 'react'
import Main from '../components/main'
import ContentWrapper from '../components/contentWrapper'
import MailChimp from '../components/mailChimp'
import PageList from '../components/pageList'
import Pagination from '../components/pagination'
// import { LayoutContext } from '../layout'
import '../styles/patterns/largeHeader.scss'
import Seo from '../components/seo'
import Img from 'gatsby-image'
import { ParallaxProvider, Parallax } from 'react-scroll-parallax'

const NewsListPage = ({ doc, items, pageNo, totalCount }) => {
  // const {
  //   current: { localization }
  // } = useRef(React.useContext(LayoutContext))

  return (
    <React.Fragment>
      <Seo
        title={doc.metaTitle}
        description={doc.metaDescription}
        ogType="website"
        url={doc.path}
      />
      <Main>
        <div
          className={
            doc.topImage ? 'large-header large-header--bg' : 'large-header'
          }
        >
          <div className="large-header__inner">
            <ContentWrapper modifier="wide">
              <div className="flex-center-column">
                <h1 className="heading-1 heading-1--larger heading-1--blue">
                  {doc.heading}
                </h1>
              </div>
            </ContentWrapper>
          </div>
          {doc.topImage && (
            <ParallaxProvider>
              <div className="large-header__overlay"></div>
              <Parallax
                className="large-header__parallax"
                y={[-20, 20]}
                tagOuter="div"
              >
                <Img
                  fluid={doc.topImage}
                  placeholderClassName="large-header__bg lazy"
                  style={{
                    position: 'absolute',
                    left: 0,
                    top: '-40px',
                    bottom: '-40px',
                    right: 0
                  }}
                />
              </Parallax>
            </ParallaxProvider>
          )}
        </div>
        <ContentWrapper>
          <PageList items={items} />
          <Pagination
            currentPage={pageNo}
            numPages={Math.ceil(totalCount / 10)}
            path={`${doc.path}/`}
          />
        </ContentWrapper>
      </Main>
      <ContentWrapper modifier="bg">
        <MailChimp />
      </ContentWrapper>
    </React.Fragment>
  )
}

export default NewsListPage
